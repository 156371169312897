/* Base surface colors from Material Dark Theme */
:root {
  --elevation-00: #121212; /* Background */
  --elevation-01: #1E1E1E; /* Cards, Dialogs */
  --elevation-02: #222222; /* Navigation drawer */
  --elevation-03: #242424; /* Floating elements */
  --primary: #BB86FC;
  --secondary: #03DAC6;
  --error: #CF6679;
}

body {
  background-color: var(--elevation-00);
  color: rgba(255, 255, 255, 0.87); /* High-emphasis text */
}



.logo{
  text-decoration: none;
  color: black;
}

.pageLinks{
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-bottom: 1%;
}
.pageLinkText{
  text-decoration:underline;
  color: black;
  font-size:x-small;
}

h1{
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.inputDiv{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.inputKeyDiv{
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  width: 100%;
  
}

.keyEntry{
  background-color: #2D2D2D !important;
}

.promptInput{
  width: 25%;
  background-color: var(--elevation-01);
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 12px;
  border-radius: 4px;
}

.apiKeyInput{
  width: 25%;
  
 }

.buttonDiv{
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  margin-top: 2%;
  
}

.enterButton{
  font-size: large;
  background-color: white;
}

.selOut{
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selOut p{
  padding: 5px;
}

.selector{
  width: 100%;
  margin-left: 0%;
  margin-bottom: 2%;
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.output{
  width: 100%;
  height: 60vh;
  text-align: left;
  background-color: var(--elevation-02);
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;
  min-height: 200px;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/* When output is focused/active */
.output:focus {
  border-color: var(--primary);
  outline: none;
}

.outputDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.individualImgOutput{
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  height: 80vh;
}

.imgOutput{
  width: 80%;
  margin-top: 5%;
}

.imgOutput img{
  width: 100%;
}

.sliderDiv{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.slider{
  max-width: 25%;
}

.footer{
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-top: 50px;
}

.copyright{
  display: flex;
  justify-content: center;
}

.PPolicy{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
}

.PPolicy p h1{
  text-align: left;
}

/* Responsive for mobile devices */
@media (max-width: 769px) {

  .outputDiv{
    display: flex;
    flex-direction: column;
  }

  .promptInput{
    width: 75%;
  }

  .slider{
    max-width: 75%;
  }

.outputDiv{
  gap: 5%;
}

.selOut{
  width: 80%;
  margin-bottom: 5%;
}

.individualImgOutput{
  width: 80%;
  height: 50vh;
}


}

/* Add these styles for loading and error states */
.loading-indicator {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: var(--elevation-01);
  border-radius: 4px;
}

.error-message {
  color: var(--error);
  margin: 10px 0;
}

.char-counter {
  text-align: right;
  color: rgba(255, 255, 255, 0.60); /* Medium emphasis text */
  margin-top: 8px;
  font-size: 0.875rem;
  padding-right: 8px;
}

/* Improve responsive layout */
@media (max-width: 768px) {
  .outputDiv {
    flex-direction: column;
  }
  
  .selOut {
    width: 95%;
    margin: 10px auto;
  }
}

/* Base styles */
.app-container {
  padding: 20px;
  margin: 0 auto;
}

/* Input section */
.inputDiv {
  width: 50%;
  margin: 0 auto 20px;
}

.promptInput {
  width: 100%;
  min-height: 30px;
  height: 30px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
}

/* Button section */
.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Output section */
.outputDiv {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.selOut {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  border-radius: 8px;
  background: #f5f5f5;
}

/* Model selector and API key input */
.keyEntry {
  margin: 15px 0 !important;
}


/* Loading and error states */
.loading-indicator {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: var(--elevation-01);
  border-radius: 4px;
}

.error-message {
  margin: 10px 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .inputDiv {
    width: 70%;
  }
  
  .outputDiv {
    flex-direction: column;
    align-items: stretch;
  }

  .selOut {
    margin: 10px 0;
  }
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .inputDiv {
    width: 95%;
  }
  
  .outputDiv {
    width: 95%;
  }

  .promptInput {
    min-height: 60px;
  }

  .selOut {
    padding: 15px;
  }

  .output {
    min-height: 150px;
  }
}

/* Header responsive styles */
@media (max-width: 600px) {
  .MuiToolbar-root {
    flex-direction: column;
    padding: 10px;
  }

  .MuiTypography-h6 {
    margin-bottom: 10px;
  }
}

/* Override Material-UI components */
.MuiPaper-root {
  background-color: var(--elevation-01) !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.MuiTextField-root {
  background-color: #2D2D2D;
}

.MuiInputBase-input {
  color: #FFFFFF !important;
}

.MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.60) !important; /* Medium-emphasis text */
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.MuiButton-contained {
  background-color: var(--primary) !important;
  color: #000000 !important; /* Dark text on light button */
}

.MuiButton-contained:hover {
  background-color: #303F9F !important;
}

.MuiSelect-icon {
  color: #FFFFFF !important;
}

.MuiMenuItem-root {
  color: #FFFFFF !important;
}

.MuiAlert-standardInfo {
  background-color: #1A237E !important;
  color: #FFFFFF !important;
}

/* Links */
a {
  color: #90CAF9;
}

a:hover {
  color: #64B5F6;
}

/* Loading indicator */
.loading-indicator {
  color: var(--primary) !important;
}

/* Dropdown menus */
.MuiSelect-select {
  background-color: #2D2D2D !important;
}

.MuiMenu-paper {
  background-color: #2D2D2D !important;
}

.MuiMenuItem-root:hover {
  background-color: #3D3D3D !important;
}

/* Security notice */
.MuiAlert-root {
  background-color: #1A237E !important;
  color: #FFFFFF !important;
}

/* Scrollbar customization */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1A1A1A;
}

::-webkit-scrollbar-thumb {
  background: #444444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

/* Error states */
.error-message {
  color: var(--error);
}

/* Disabled states */
.MuiButton-contained.Mui-disabled {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.38) !important;
}

/* Fix dropdown labels and subheaders */
.MuiListSubheader-root {
  background-color: var(--elevation-02) !important;
  color: rgba(255, 255, 255, 0.60) !important; /* Medium emphasis */
}

/* Fix output text boxes */
.MuiInputBase-root {
  background-color: var(--elevation-01) !important;
}

.MuiSelect-select {
  background-color: var(--elevation-01) !important;
}

/* Fix dropdown menu */
.MuiMenu-paper {
  background-color: var(--elevation-02) !important;
}

.MuiMenuItem-root {
  background-color: var(--elevation-02) !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--elevation-03) !important;
}

/* Fix text area backgrounds */
textarea {
  background-color: var(--elevation-01) !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

/* Fix any remaining white backgrounds in selectors */
.selOut {
  background-color: var(--elevation-01) !important;
}

.MuiSelect-outlined {
  background-color: var(--elevation-01) !important;
}

/* Style the MUI CircularProgress specifically */
.MuiCircularProgress-root {
  color: var(--primary) !important;
}

/* Loading text if present */
.loading-indicator span {
  color: rgba(255, 255, 255, 0.60); /* Medium emphasis text */
  margin-left: 8px;
}

/* Footer spacing */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  margin-bottom: 48px; /* Space between content and footer */
}

/* Ensure footer stays at bottom */
footer {
  margin-top: 50px;
}